const sectionImg = {
  width: 900,
  height: 620,
};

const shoppingListSvg = {
  width: 40,
  height: 40,
};

const socialImg = {
  fb: {
    width: 24,
    height: 24,
  },
  inst: {
    width: 20,
    height: 20,
  },
  li: {
    width: 20,
    height: 20,
  },
};

const appImg = {
  width: 170,
  height: 48,
};

const downloadAppImg = {
  width: 630,
  height: 730,
};

const partnerLogoImg = {
  walmart: {
    width: 80,
    height: 20,
  },
  kroger: {
    width: 60,
    height: 48,
  },
  homedepot: {
    width: 48,
    height: 48,
  },
  cvs: {
    width: 83,
    height: 48,
  },
  toysrus: {
    width: 83,
    height: 26,
  },
  meijer: {
    width: 83,
    height: 32,
  },
  familydollar: {
    width: 83,
    height: 11,
  },
  dicks: {
    width: 82,
    height: 44,
  },
  Walgreens: {
    width: 86,
    height: 54,
  },
  RiteAid: {
    width: 86,
    height: 54,
  },
  Publix: {
    width: 86,
    height: 54,
  },
  Meijer: {
    width: 86,
    height: 54,
  },
  FamilyDollar: {
    width: 86,
    height: 54,
  },
  CVS: {
    width: 86,
    height: 54,
  },
};

const MATCHER_USA = /^\d{5}$/;
const MATCHER_CANADA = /^[A-Za-z][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9]$/;

const checkIsValidPostalCode = (code: string): boolean => {
  const isTrue = 0;
  const startPosition = 1;
  let zipCode = code;

  if (zipCode?.substr(-startPosition) === '\\') {
    zipCode = zipCode.substr(isTrue, zipCode.length - startPosition);
  }

  return zipCode.search(MATCHER_USA) >= isTrue || zipCode.search(MATCHER_CANADA) >= isTrue;
};

const isUSAPostalCode = (code: string): boolean => {
  return Boolean(code.match(MATCHER_USA));
};

const isCanadianPostalCode = (code: string): boolean => {
  return Boolean(code.match(MATCHER_CANADA));
};

export {
  checkIsValidPostalCode,
  isUSAPostalCode,
  isCanadianPostalCode,
  downloadAppImg,
  socialImg,
  appImg,
  partnerLogoImg,
  sectionImg,
  shoppingListSvg,
};
