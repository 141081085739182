import React, { useState, useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';

import useUserAgent from '../../hooks/useUserAgent';
import styles from './appRating.module.scss';

const THOUSAND_STARS_RATING = 1000;
const MILLION_STARS_RATING = 1000000;
const FRACTION_DIGITS_0 = 0;
const FRACTION_DIGITS_1 = 1;

interface IAppStoreRating {
  appStore: {
    rating: number;
    reviews: number;
  };
  playMarket: {
    rating: number;
    reviews: number;
  };
}

export default function AppRating(): JSX.Element {
  const [appRating, setAppRating] = useState<IAppStoreRating>();
  const [stars, setStars] = useState<string[]>([]);
  const [formattedReviews, setFormattedReviews] = useState<string>();

  const { isIOs } = useUserAgent();
  const { lang } = useTranslation('home');

  useEffect(() => {
    if (appRating) {
      const { appStore, playMarket } = appRating;
      const appReviews = isIOs ? appStore : playMarket;
      const { reviews } = appReviews;
      let data = '';
      if (reviews <= THOUSAND_STARS_RATING) {
        data = `${reviews}`;
      } else if (reviews < MILLION_STARS_RATING) {
        data = `${(reviews / THOUSAND_STARS_RATING).toFixed(FRACTION_DIGITS_0)}k`;
      } else {
        data = `${(reviews / MILLION_STARS_RATING).toFixed(FRACTION_DIGITS_1)}M`;
      }
      setFormattedReviews(data);
    }
  }, [appRating]);

  useEffect(() => {
    if (appRating) {
      const { appStore, playMarket } = appRating;
      const appReviews = isIOs ? appStore : playMarket;
      const { rating } = appReviews;
      const starsArray = [];
      for (let i = 0; i < Math.ceil(rating); i++) {
        starsArray.push('https://f.wishabi.net/creative/Distribution/images/icons/rating-star.svg');
      }
      setStars(starsArray);
    }
  }, [appRating]);

  useEffect(() => {
    const getAppRating = process.env.NEXT_PUBLIC_FLIPPBACK_API_HOST + `/app/ratings/${lang}`;
    const fetchData = async () => {
      const res = await fetch(getAppRating);
      const data = await res.json();
      setAppRating(data);
    };
    fetchData();
  }, []);

  return (
    <div className={styles.ratingWrapper}>
      <div className={styles.starsWrapper}>
        {stars.map((star, index) => (
          <img key={index} src={star} alt="star" />
        ))}
        <div className={styles.reviews}>{formattedReviews}</div>
      </div>
    </div>
  );
}
