import { useState, useEffect } from 'react';

interface IUserAgentData {
  isMobile: boolean;
  isIOs: boolean;
  isSafari: boolean;
  isGooglebot: boolean;
  isLighthouse: boolean;
}

export default function useUserAgent(): IUserAgentData {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isSafari, setIsSafari] = useState<boolean>(false);
  const [isIOs, setIsIOs] = useState<boolean>(false);
  const [isGooglebot, setIsGooglebot] = useState<boolean>(false);
  const [isLighthouse, setIsLighthouse] = useState<boolean>(false);

  useEffect(() => {
    const navigator = window.navigator;
    const android = /Android/.test(navigator.userAgent);
    const iOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
    const firefox = /Firefox|FxiOS/.test(navigator.userAgent);
    const chrome = /Chrome|CriOS/.test(navigator.userAgent);
    const chromeHeadless = /HeadlessChrome/i.test(navigator.userAgent);
    const googleBot = /Googlebot/i.test(navigator.userAgent) || chromeHeadless;
    const lightHouse = /Chrome-Lighthouse/i.test(navigator.userAgent);

    setIsIOs(iOS);
    setIsGooglebot(googleBot);
    setIsLighthouse(lightHouse);
    setIsMobile(
      android ||
        iOS ||
        // Firefox OS
        (firefox && /Mobile|Tablet|TV/.test(navigator.userAgent)),
    );

    // Firefox & Chrome on iOS report Safari, since they use UIWebView
    setIsSafari(/Safari/.test(navigator.userAgent) && !firefox && !chrome);
  }, []);

  return { isMobile, isSafari, isIOs, isGooglebot, isLighthouse };
}
