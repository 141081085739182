// https://developers.google.com/analytics/devguides/collection/gtagjs/pages#manual_pageviews
export const pageview = ({ page_title, page_path }) => {
  if (window.gtag) {
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path,
      page_title,
      send_page_view: true,
    });
  }
};
