export interface IStorage {
  getItem: (key: string) => string;
  setItem: (key: string, value: string) => void;
}

export default function useSessionStorage(): IStorage {
  const getItem = (key: string) => {
    try {
      return window.sessionStorage.getItem(key);
    } catch (e) {
      return undefined;
    }
  };

  const setItem = (key: string, value: string) => {
    try {
      window.sessionStorage.setItem(key, value);
    } catch (e) {
      console.error('session storage is not available');
    }
  };

  return { getItem, setItem };
}
