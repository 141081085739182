import { useState, useEffect } from 'react';

interface IImageCssClass {
  imageCssClass: string;
}

export default function useProgressiveImage(): IImageCssClass {
  const [cssClass, setCssClass] = useState<string>();
  useEffect(() => {
    const avif = new Image();
    avif.src =
      'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=';
    avif.onload = function () {
      setCssClass('avif');
    };
    avif.onerror = function () {
      checkWebpFeature(function (isSupported) {
        if (isSupported) {
          return setCssClass('webp');
        }
        return setCssClass('fallback');
      });
    };
  }, []);

  function checkWebpFeature(callback) {
    const img = new Image();
    img.onload = function () {
      const result = img.width > 0 && img.height > 0;
      callback(result);
    };
    img.onerror = function () {
      callback(false);
    };
    img.src = 'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==';
  }

  return { imageCssClass: cssClass };
}
