import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { getCookie, setCookie } from 'react-use-cookie';
import cx from 'classnames';

import AppRating from '../appRating/appRating';
import useUserAgent from '../../hooks/useUserAgent';
import useBanners from '../../hooks/useBanners';
import useCookiesConfig from '../../hooks/useCookiesConfig';
import FlippLogo from '../../widgets/flippLogo';
import styles from './mobileBanner.module.scss';
import useBlackFriday from '../../hooks/useBlackFriday';

const WHEN_WAS_HIDDEN_NAME = 'when-was-hidden-mobile-banner';
const BANNER_EXPIRATION_LIMIT = 864e5;

export default function MobileBanner(): JSX.Element {
  const { t, lang } = useTranslation('home');
  const [isFrench, setIsFrench] = useState<boolean>(false);
  const { isLighthouse, isMobile } = useUserAgent();
  const { isSmartNews } = useBanners();
  const { getCookiesDomain } = useCookiesConfig();
  const { isBlackFridayEnabled } = useBlackFriday({});
  const [isBannerHidden, setIsBannerHidden] = useState<boolean>(true);

  useEffect(() => {
    const whenWasHidden = getCookie(WHEN_WAS_HIDDEN_NAME);
    if (isLighthouse || !isMobile || isSmartNews) {
      setIsBannerHidden(true);
    } else if (!whenWasHidden) {
      setIsBannerHidden(false);
    } else {
      setIsBannerHidden(Math.abs(new Date().getTime() - parseInt(whenWasHidden)) <= BANNER_EXPIRATION_LIMIT);
    }
  }, [isMobile, isLighthouse, isSmartNews]);

  useEffect(() => {
    setIsFrench(lang === 'fr-ca');
  }, [lang]);

  const hideBanner = () => {
    setCookie(WHEN_WAS_HIDDEN_NAME, new Date().getTime().toString(), {
      days: 1,
      path: getCookiesDomain(),
    });
    setIsBannerHidden(true);
  };

  return isBannerHidden ? null : (
    <div className={styles.mobileBanner} data-cy="mobileBanner">
      <div className={styles.mobileBanner__close} onClick={hideBanner}>
        <div>
          <img
            className="myCloseIcon"
            src="https://f.wishabi.net/creative/Distribution/images/icons/myCloseIcon.svg"
            alt=""
          />
        </div>
      </div>
      <div className={styles.mobileBanner__logo}>
        <div>
          <FlippLogo />
        </div>
      </div>
      <div className={styles.mobileBanner__links}>
        <div className={styles.mobileBannerWrapper}>
          <div
            className={cx(styles.mobileBanner__links__body, {
              [`${styles.mobileBanner__links__bodyFrench}`]: isFrench,
            })}
          >
            {isBlackFridayEnabled ? t('mobile_banner_bf') : t('mobile_banner')}
          </div>
          <div className={styles.mobileBannerRating}>
            <AppRating />
          </div>
        </div>
        <div className={styles.mobileBannerBtnWrapper}>
          <div
            className={cx(styles.mobileBanner__links__button, {
              [`${styles.mobileBanner__links__buttonFrench}`]: isFrench,
            })}
          >
            <a href="https://click.flipp.com/h8pE/1c6a8a4d">{t('get_app')}</a>
          </div>
        </div>
      </div>
    </div>
  );
}
