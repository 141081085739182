import { useState, useEffect } from 'react';
import useSessionStorage from './useSessionStorage';

interface IBanners {
  isSmartNews: boolean;
}

export default function useBanners(): IBanners {
  const [isSmartNews, setIsSmartNews] = useState<boolean>(false);
  const { getItem, setItem } = useSessionStorage();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const usynd = params.get('synd');
    if (usynd === 'smartnews') {
      setIsSmartNews(true);
      setItem('synd', usynd);
    } else {
      const synd = getItem('synd');
      setIsSmartNews(synd === 'smartnews');
    }
  }, []);

  return { isSmartNews };
}
