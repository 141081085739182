import React from 'react';

export default function FlippLogo() {
  return (
    <svg focusable="false" viewBox="0 0 2048 1436" className="flipp-logo">
      <path d="M682.8,786.2c16.9,51.4,96.4,69.5,141.7,40c10.9-7.1,20.2-18.5,27.7-30.5c6.8-10.9-5-24-16.5-18.2 c-1.6,0.8-3.2,1.6-4.9,2.3c-20.8,9.1-33.1-1.8-26.9-23.7c0.6-2,1.2-4.4,2.1-7.4c30.9-113.7,65.2-226.5,85.7-299.2 c2.6-9.4-5.7-18.1-15.2-15.8L766.5,455c-4.5,1.1-8.1,4.4-9.3,8.8c-12.6,43.8-36,136.4-68.9,233.4 C675.3,735.5,675.6,764.4,682.8,786.2z" />
      <circle cx="860.9" cy="337.8" r="63" />
      <path d="M684.6,302.3l-109.9,21.3c-4.5,1.1-8.1,4.4-9.3,8.8c-12.6,43.8-74,265.7-106.9,362.7 c-12.9,38.2-12.7,67.2-5.5,89c16.9,51.4,95.4,71.3,140.7,41.7c15.3-10,22.4-22.3,25.6-32.6c2.4-7.7-4.8-14.9-12.5-12.5 c-4.9,1.5-11.4,3-19.8,4.3c-8.4,1.2-16-5.1-16.3-13.5c-0.3-8.2,0.3-8.4,4.4-23.2c30.9-113.7,104.2-357.6,124.7-430.2 C702.5,308.7,694.1,300,684.6,302.3z" />
      <path d="M311.7,566.6c1.4-4.4,2.9-8.8,4.4-13.3l111.8-21.6c5.3-1,9.6-4.8,11.3-10l21.9-66.8 c3.5-10.8-5.8-21.4-17-19.3l-96.5,18.7c33.1-105.1,69.2-217.4,98.8-264.7c55.5-88.6,117.6-49.4,125.1-44.2 c-24,8.9-41.2,31.9-41.2,59c0,34.1,20.1,62.6,69.7,62.6c63.9,0,97.7-49.8,97.7-102.1c0-68.7-54.3-111.4-128.8-129.7 c-95-23.3-199.4,18.5-249.1,100.8c-33.8,56-83.8,217.9-114.9,325.8l-0.2-0.2c-1.1,3.9-3.2,11.2-6.2,21.4l-139.4,27 c-5.3,1-9.6,4.8-11.3,10l-21.9,66.8c-3.5,10.8,5.8,21.4,17,19.3L170,581.6l-16.4,56.3c-43.2,148.3-101.6,346.9-127.3,427 c-2.6,8.2,4.7,16.1,13.1,14.1l18.6-4.5c67.8-16.3,121.8-67.4,141.7-134.2c12.2-41.1,60.9-206.4,88.5-300.4L311.7,566.6z" />
      <path d="M1411.7,955c0.7-2,39.8-131.9,39.8-131.9s12.7,46.6,87.9,46.6c74.6,0,183-46.6,218.4-176.8 c36.1-132.7,16.7-227.2-70.7-255.1c-57.2-19.9-138.2,43.5-138.2,43.5c3.6-12.7,8.5-25.3,10.9-33.7c2.6-9.4-5.7-18.1-15.2-15.8 l-113.7,21.1c-4.5,1.1-8.1,4.4-9.3,8.8c-9.1,31.6-86.8,299.5-140.2,477.9C1321.5,945.2,1363.4,950.5,1411.7,955z M1529,551.2 c30.2-31.5,65.5-46,94-34.2c45.1,18.7,55.8,96.4,23.8,173.5c-32,77.1-94.5,124.4-139.6,105.7c-16.9-7-29-22.3-35.8-42.7L1529,551.2 z" />
      <path d="M990.6,898.2c12.2-40.9,22.4-75.1,22.4-75.1s12.7,46.6,87.9,46.6c74.6,0,183-46.6,218.4-176.8 c36.1-132.7,16.7-227.2-70.7-255.1c-57.2-19.9-138.2,43.5-138.2,43.5c3.6-12.7,8.5-25.3,10.9-33.7c2.6-9.4-5.8-18.1-15.2-15.8 l-113.7,21.1c-4.5,1.1-8.1,4.4-9.3,8.8c-8.4,29-71.6,248-123.3,422.1C903.7,887.3,946.8,892.8,990.6,898.2z M1090.5,551.2 c30.2-31.5,65.5-46,94-34.2c45.1,18.7,55.8,96.4,23.8,173.5c-32,77.1-94.5,124.4-139.6,105.7c-16.9-7-29-22.3-35.8-42.7 L1090.5,551.2z" />
      <path d="M1943.4,873.2c0,0-9.5-2.9-18.6,8.9c-27.1,35.3-103.3,107.6-277.9,121.1c-210.3,16.3-469.6-38.3-617.7-56.3 c-150.7-18.3-370.3-72.6-626.1,17.3C230.4,1025,192.5,1167.3,221,1264.1c36.6,124.4,249.8,165.4,375.5,131.7 c126.2-33.8,270.3-126,333.3-286l-131.1-16.2c0,0-81.3,144.8-220.7,189.5c-121.6,39-239.9-13.4-234.7-76.4 c8.1-98.2,160.3-215.2,667.7-129.7c295.2,49.7,526.7,64.8,634.3,64.8c306.8,0,368.8-78.8,368.8-78.8S2059.6,930.7,1943.4,873.2z" />
    </svg>
  );
}
