import React, { useCallback, ReactNode } from 'react';

export interface SearchInputInterface {
  wrapperClassName?: string;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
  name?: string;
  onBlur?: (number) => void;
  onChange: (number) => void;
  onKeyDown?: (event: React.KeyboardEvent<any>) => any;
  children: ReactNode;
  maxLength: number;
}

const SearchInput = ({
  maxLength,
  placeholder,
  onChange,
  onKeyDown,
  wrapperClassName,
  children,
  ...props
}: SearchInputInterface) => {
  const handleChange = useCallback((e) => onChange(e.target.value), [onChange]);
  return (
    <div className={wrapperClassName}>
      <input onKeyDown={onKeyDown} maxLength={maxLength} placeholder={placeholder} onChange={handleChange} {...props} />
      {children}
    </div>
  );
};

export { SearchInput };
