interface ICookiesConfig {
  getCookiesDomain: () => string;
}

export default function useCookiesConfig(): ICookiesConfig {
  const getCookiesDomain = () => {
    if (process.env.NEXT_PUBLIC_HOSTNAME === 'localhost') {
      return 'localhost';
    }

    return '.flipp.com';
  };

  return { getCookiesDomain };
}
